
import { computed, ComputedRef, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue';
import { ElMessage, ElMessageBox } from 'element-plus';
import permissionUnits from '@/components/permission-units/index.vue';
import { useCampaigns, useDeleteCampaign } from '@/composables/api';
import {
  CampaignIdOption,
  DELETED_SUCCESSFULLY_TEXT,
  exportCampaignsDaily,
  getChannelAgents,
  MAX_PER_PAGE,
  SortOrder,
  DEFAULT_PAGE_SIZE,
  OrderSortKey
} from '@/services/api';

import { formatLocalTime } from '@/utils/format-time';
import { getThousandSeparator } from '@/utils/render';
import dayjs from 'dayjs';
import download from 'downloadjs';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useRoute, useRouter } from 'vue-router';
import { ChannelAgent } from '@/interfaces';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { useKeyword, usePage, useSort } from '@/composables';

export default defineComponent({
  components: {
    permissionUnits,
    SearchBox,
    Filter
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const currentIndex = ref();
    const channelAgentId = ref<string>();

    const route = useRoute();
    const router = useRouter();
    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { data, isLoading, isFetching, refetch } = useCampaigns({ page, pageSize, keyword, channelAgentId, sortKey, sort: sortOrder });
    const { isLoading: isDeleting, mutate } = useDeleteCampaign();

    const deleteCampaign = ({ campaignId }: CampaignIdOption, index: Number) => {
      currentIndex.value = index;

      ElMessageBox.confirm('是否確認要刪除？', '警告',
        {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          mutate({ campaignId }, {
            onSuccess() {
              refetch.value();

              ElMessage.success(DELETED_SUCCESSFULLY_TEXT);
            },
            onError(error: any) {
              ElMessage.error(error.response?.data.message);
            }
          });
        })
        .catch();
    };

    const handleExcelExport = async() => {
      try {
        const data = await exportCampaignsDaily();
        const currentDate = dayjs().format('YYYY-MM-DD');

        download(data, `${currentDate}渠道合作.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      } catch (error: any) {
        ElMessage.error(error.response?.data.message);
      }
    };

    watch(route, () => {
      if (!route.query.page) return;

      page.value = Number(route.query.page);
    }, { deep: true });

    /**
     * channelAgents
     */
    const channelAgents = ref<ChannelAgent[]>([]);
    const channelAgentsOptions = ref([]);
    const fetchChannelAgents = async() => {
      channelAgents.value = (await getChannelAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };
    watch(() => channelAgents.value, () => {
      channelAgentsOptions.value = channelAgents.value.map(({ username, id }) => ({ label: `${username}`, value: `${id}` }));
    });
    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '代理帳號',
          placeholder: '請選擇',
          options: channelAgentsOptions.value
        }
      ];
    });
    const handleFilterChange = (event: FilterEvent) => {
      channelAgentId.value = event[0];
    };

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      sortOrder.value = (order === 'ascending') ? SortOrder.ASCENDING : SortOrder.DESCENDING;
    };

    onMounted(() => {
      const routeQuery = route.query;

      if (!routeQuery.page) {
        router.push({ query: { page: page.value } });
      } else {
        page.value = Number(routeQuery.page);
      }

      fetchChannelAgents();
    });

    onUnmounted(() => {
      route.query.page = String(page.value);
    });

    return {
      page,
      data,
      isLoading,
      isFetching,
      isDeleting,
      currentIndex,
      keyword,
      setPageQuery,
      deleteCampaign,
      formatLocalTime,
      handleExcelExport,
      getThousandSeparator,
      filterOptions,
      handleFilterChange,
      sortChange
    };
  }
});
