
import { computed, defineComponent, onMounted, PropType, ref, toRefs } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { debounce, get, head, isEmpty, isNil, some } from 'lodash';
import download from 'downloadjs';
import { getThousandSeparator } from '@/utils/render';
import { formatLocalTime } from '@/utils/format-time';
import { CpaOverviewData, createCpaRule, DEFAULT_PAGE_SIZE, getCpaOverview, ResponseError, exportCpa, CpaRuleData } from '@/services/api';
import { Campaign } from '@/interfaces';
import { useCpaStatistics } from '@/composables/api';
import { usePage } from '@/composables/page';

const CREATE_TYPE_TRANSLATE = {
  register: '註冊',
  subscribe: '7日體驗'
};

const CREATE_RULE_OPTION = [
  { name: '註冊', key: 'register' },
  { name: '7日體驗', key: 'subscribe' }
];

export default defineComponent({
  components: {
  },
  props: {
    data: {
      type: Object as PropType<Campaign>,
      default: () => ({})
    }
  },
  setup(props) {
    const { data: campaignData } = toRefs(props);
    const router = useRouter();
    const campaignId = useRoute().params.id as string;
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const statisticDateRange = ref();
    const startedAt = ref('');
    const endedAt = ref('');

    const handleDateRangeChange = () => {
      if (!statisticDateRange.value) {
        startedAt.value = '';
        endedAt.value = '';
        return;
      }

      startedAt.value = statisticDateRange.value[0];
      endedAt.value = statisticDateRange.value[1];
      setPageQuery(1);
    };

    const {
      data: cpaStatistics,
      isLoading,
      isFetching,
      refetch
    } = useCpaStatistics({ page, pageSize, startedAt, endedAt, id: +campaignId });

    const cpaRate = computed(() => {
      const data = head(get(cpaStatistics.value, 'data', []));
      return {
        register: get(data, 'registerAmount', 0),
        subscriptionAmount: get(data, 'subscriptionAmount', 0)
      };
    });

    /** 匯出 cpa 數據 */
    const exportCpaDetail = async() => {
      try {
        const data = await exportCpa({ id: +campaignId }, {
          query: {
            startedAt: startedAt.value,
            endedAt: endedAt.value
          }
        });
        download(
          data,
          `${campaignData.value.utmCampaign}-cpa.xlsx`,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        );
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const createRuleType = ref('');
    const createRuleAmount = ref(null);
    const createRuleData = ref<CpaRuleData[]>([]);
    const isRuleDialogVisible = ref(false);

    const statisticTableData = computed(() => get(cpaStatistics.value, 'data', []));

    const handleOpenRuleDialog = () => {
      isRuleDialogVisible.value = true;
    };

    const handleCloseRuleDialog = () => {
      isRuleDialogVisible.value = false;
      createRuleType.value = '';
      createRuleAmount.value = null;
      createRuleData.value = [];
    };

    /** 檢查是否重複加入條件（每個條件最多一次） */
    const checkDisabledOption = (key: string) => {
      return some(createRuleData.value, item => item.type === key);
    };

    /** 增加 cpa 條件 */
    const handleAddRule = () => {
      if (!createRuleType.value) {
        ElMessage.error('請選擇合作條件');
        return;
      }
      if (!+createRuleAmount.value) {
        ElMessage.error('請輸入合作費用');
        return;
      }

      createRuleData.value = [...createRuleData.value, {
        type: createRuleType.value,
        amount: createRuleAmount.value
      }];
      createRuleType.value = '';
      createRuleAmount.value = null;
    };

    const createRule = debounce(async() => {
      if (isEmpty(createRuleData.value)) {
        ElMessage.error('請新增條件');
        return;
      }

      try {
        const { data } = await createCpaRule({ id: +campaignId }, {
          data: createRuleData.value
        });
        handleCloseRuleDialog();
        refetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    }, 200, { trailing: false, leading: true });

    /** cpa 總覽 */
    const cpaOverview = ref<CpaOverviewData>();

    const fetchCpaOverview = async() => {
      try {
        const { data } = await getCpaOverview(+campaignId);
        cpaOverview.value = data;
        console.log('fetchCpaOverview data ', data);
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    onMounted(() => {
      fetchCpaOverview();
    });

    return {
      // 通用
      isNil,
      getThousandSeparator,
      formatLocalTime,
      router,
      campaignId,

      // 新增 cap 條件
      CREATE_TYPE_TRANSLATE,
      CREATE_RULE_OPTION,
      isRuleDialogVisible,
      handleOpenRuleDialog,
      handleCloseRuleDialog,
      createRuleType,
      createRuleAmount,
      createRuleData,
      createRule,
      handleAddRule,
      checkDisabledOption,

      // cpa 數據
      exportCpaDetail,
      startedAt,
      endedAt,
      statisticDateRange,
      cpaStatistics,
      isLoading,
      isFetching,
      page,
      pageSize,
      setPageQuery,
      statisticTableData,
      handleDateRangeChange,

      // cpa 總覽
      cpaOverview,
      cpaRate
    };
  }
});
