
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  ref,
  watch
} from 'vue';
import { useRoute } from 'vue-router';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import {
  useBlock,
  useFeatureWorks,
  useWorks
} from '@/composables/api';
import {
  getGenres,
  getTags,
  updateBlockFeatureWork,
  getClassifications,
  createBlockFeatureWorks,
  deleteBlockFeatureWorks,
  ResponseError
} from '@/services/api';
import { forEach, get, includes, map } from 'lodash';
import { DEFAULT_PAGE_SIZE, MAX_PER_PAGE } from '@/services/api/constants';
import { Classification, Genre, Tag, WorkStatus } from '@/interfaces';
import { ElMessage } from 'element-plus';
import { useKeyword, usePage } from '@/composables';

interface WorkMetaInfo {
  workId: number
  isFeature: boolean
}

interface SwitchChangeEvent {
  row?: any
  workId?: number
  includes?: boolean
  index?: number
}

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  emits: ['back'],
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const status = ref(WorkStatus.PUBLISHED);
    const workMetaInfos = ref<WorkMetaInfo[]>([]);
    const blockId = +useRoute().params.id;

    const classificationIds = ref([]);
    const classifications = ref<Classification[]>([]);
    const classificationOptions = computed(() => classifications.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const genreId = ref();
    const genres = ref<Genre[]>([]);
    const genreOptions = computed(() => genres.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const tagId = ref();
    const tags = ref<Tag[]>([]);
    const tagOptions = computed(() => tags.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));

    const { data, isLoading, isFetching } = useWorks({ page, pageSize, keyword, status, genreId, tagId, classificationIds });
    const { data: block, refetch } = useBlock(blockId);

    const featureWorks = computed(() => get(block.value, 'data.featureWorks', []));
    const featureWorksIds = computed(() => map(featureWorks.value, 'id'));

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchGenres = async() => {
      genres.value = (await getGenres({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '影片類型',
          placeholder: '請選擇',
          options: genreOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '主類型',
          placeholder: '請選擇',
          options: classificationOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '標籤',
          placeholder: '請選擇',
          options: tagOptions.value
        }
      ];
    });

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      genreId.value = event[0];
      classificationIds.value[0] = event[1];
      tagId.value = event[2];
    };

    const checkIsSwitchOn = (featureWorkIds: number[]) => {
      forEach(workMetaInfos.value, (item) => {
        if (!includes(featureWorkIds, item.workId)) {
          item.isFeature = false;
          return;
        }

        item.isFeature = true;
      });
    };

    onMounted(() => {
      fetchGenres();
      fetchTags();
      fetchClassifications();
    });

    watch(data, async(workResponse) => {
      refetch.value();
      workMetaInfos.value = workResponse?.data?.map(({ id }) => ({
        workId: id,
        isFeature: includes(featureWorksIds.value, id)
      }));
    }, { immediate: true });

    watch(featureWorksIds, async(newFeatureWorksIds) => {
      checkIsSwitchOn(newFeatureWorksIds);
    }, { immediate: true });

    const handleSwitchChange = async({ workId, includes, index }: SwitchChangeEvent) => {
      try {
        if (includes && featureWorks.value.length >= 4) {
          workMetaInfos.value[index].isFeature = false;
          ElMessage.error('主打影片已達上限');
          return;
        }

        const options = {
          blockId,
          data: {
            works: [{ id: workId }]
          }
        };

        if (includes) {
          createBlockFeatureWorks(options);
        } else {
          deleteBlockFeatureWorks(options);
        }

        refetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    return {
      page,
      keyword,
      setPageQuery,
      data,
      isLoading,
      isFetching,
      workMetaInfos,
      filterOptions,
      handleSwitchChange,
      handleFilterChange
    };
  }
});
