
import { defineComponent } from 'vue';
import { CountTo } from 'vue3-count-to';

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => null
    }
  },
  components: {
    CountTo
  },
  setup() {
    return {};
  }
});
