
import { defineComponent, ref, watch } from 'vue';
import { ElMessageBox, ElMessage } from 'element-plus';

import { useTags, useBlocks } from '@/composables/api';
import { TagIdOption, DEFAULT_PAGE_SIZE, DELETED_SUCCESSFULLY_TEXT } from '@/services/api';

import permissionUnits from '@/components/permission-units/index.vue';
import { useRoute, useRouter } from 'vue-router';
import { formatLocalTime } from '@/utils/format-time';
import { Block, Topic } from '@/interfaces';
import { filter, size } from 'lodash';

export default defineComponent({
  components: {
    permissionUnits
  },
  setup() {
    const {
      data: blocks,
      isLoading: isBlockLoading,
      isFetching: isBlockFetching,
      refetch: blockRefetch
    } = useBlocks();

    const route = useRoute();
    const router = useRouter();

    watch(blocks, () => {
      console.log(blocks.value);
    });

    return {
      size,
      formatLocalTime,
      blocks,
      isBlockLoading,
      isBlockFetching
    };
  }
});
