
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  ref,
  toRefs,
  watch
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { ElMessage } from 'element-plus';
import SortTopic from './components/SortTopic.vue';
import FeatureWorkDetail from './components/FeatureWorkDetail.vue';
import { useBlock, useTopics, useBlocks } from '@/composables/api';
import {
  getClassifications, deleteBlockTopics, createBlockTopics, sortBlockTopics, ResponseError, deleteBlockFeatureWorks, sortBlockFeatureWorks
} from '@/services/api';
import { Classification, Topic, Work } from '@/interfaces';
import { filter, find, get, map } from 'lodash';

type Mode = 'sort' | 'normal' | 'worksEdit'
type TabName = 'normal' | 'popular' | 'featureWorks'

export default defineComponent({
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  components: { SortTopic, FeatureWorkDetail },
  setup(props) {
    // #region 全域
    const { isEdit } = toRefs(props);
    const blockId = useRoute().params.id as string;
    const router = useRouter();
    const { t } = useI18n();

    /** 頁籤（主題、熱門主題） */
    const currentTab = ref<TabName>('featureWorks');
    /** 編輯模式（正常、排序） */
    const editMode = ref<Mode>('normal');
    const currentData = ref<Topic | Work>();
    const showDeleteDialog = ref(false);

    /** 全域 config */
    const tabs = [
      {
        label: '主打影片',
        name: 'featureWorks',
        columns: [
          {
            label: '項次',
            key: 'id',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.id || '-'
            }
          },
          {
            label: '影片名稱',
            key: 'name',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.name || '-'
            }
          },
          {
            label: '有標圖片',
            key: 'captionCoverPhoto',
            align: 'center',
            content: {
              type: 'image',
              value: (row) => get(row, 'captionCoverPhoto.path', '')
            }
          }
        ]
      },
      {
        label: '主題',
        name: 'normal',
        columns: [
          {
            label: '項次',
            key: 'id',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.id || '-'
            }
          },
          {
            label: '主題名稱',
            key: 'name',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.name || '-'
            }
          },
          {
            label: '主題呈現方式',
            key: 'style',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.type !== undefined ? t(`topicStyle.${row.style}`) : '-'
            }
          }
        ]
      },
      {
        label: '熱門主題',
        name: 'popular',
        columns: [
          {
            label: '項次',
            key: 'id',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.id || '-'
            }
          },
          {
            label: '主題名稱',
            key: 'name',
            align: 'center',
            content: {
              type: 'text',
              value: (row) => row.name || '-'
            }
          },
          {
            label: '熱門主題內頁banner',
            key: 'bannerPhoto',
            align: 'center',
            content: {
              type: 'image',
              value: (row) => get(row, 'bannerPhoto.path', '')
            }
          },
          {
            label: '熱門主題底圖',
            key: 'thumbnailPhoto',
            align: 'center',
            content: {
              type: 'image',
              value: (row) => get(row, 'thumbnailPhoto.path', '')
            }
          }
        ]
      }
    ];

    const formattedTab = computed(() => {
      return find(tabs, t => t.name === currentTab.value);
    }); ;
    // #endregion 全域

    // #region 場館
    const {
      data: block,
      isLoading: isBlockLoading,
      isFetching: isBlockFetching,
      refetch: blockRefetch
    } = useBlock(+blockId);

    const currentBlock = computed(() => block.value?.data);
    // #endregion 場館

    // #region 主打影片
    const tempSortFeatureWorks = ref<Work[]>([]);

    const handleDeleteBlockFeatureWork = async(id: number) => {
      try {
        await deleteBlockFeatureWorks({ blockId, data: { works: [{ id }] } });
        showDeleteDialog.value = false;
        blockRefetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const handleSortBlockFeatureWorks = async(works: { id: number }[]) => {
      try {
        await sortBlockFeatureWorks({ blockId, data: { works } });
        blockRefetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const handleFeatureWorkBack = () => {
      blockRefetch.value();
      editMode.value = 'normal';
    };
    // #endregion 主打影片相關

    // #region 主類型
    const classificationIds = ref<number>(null);
    const classifications = ref<Classification[]>([]);

    const formattedClassificationIds = computed(() =>
      !classificationIds.value ? [] : [classificationIds.value]);

    const fetchClassifications = async() => {
      try {
        classifications.value = (await getClassifications()).data;
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };
    // #endregion 主類型相關

    // #region 主題、熱門主題
    const tempSortNormalTopics = ref<Topic[]>([]);
    const tempSortPopularTopics = ref<Topic[]>([]);
    /** 已選取的主題（新增主題） */
    const selectedTopics = ref<number[]>([]);
    /** 新增主題彈窗開關 */
    const showAddTopicDialog = ref(false);
    /** 搜尋主題關鍵字 */
    const searchKeyword = ref('');

    const popularTopics = computed<Topic[]>(() => filter(
      get(block.value, 'data.topics', []), topic => topic.isPopular
    ));
    const normalTopics = computed<Topic[]>(() => filter(
      get(block.value, 'data.topics', []), topic => !topic.isPopular
    ));
    /** 篩選掉已加入的主題 */
    const blacklistIds = computed(() =>
      map(get(block.value, 'data.topics', []), 'id'));

    const {
      data: topics,
      isLoading: isTopicLoading,
      isFetching: isTopicFetching,
      refetch
    } = useTopics({ keyword: searchKeyword, blacklistIds, classificationIds: formattedClassificationIds });

    const handleTopicsSort = (data) => {
      switch (currentTab.value) {
        case 'normal':
          tempSortNormalTopics.value = data;
          break;
        case 'popular':
          tempSortPopularTopics.value = data;
          break;
      };
    };

    const handleSortBlockTopics = async(
      topics: { id: number, isPopular: boolean }[]
    ) => {
      try {
        await sortBlockTopics({ blockId, data: { topics } });
        blockRefetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const handleCreateBlockTopics = async() => {
      try {
        const topics = currentTab.value === 'normal'
          ? map(selectedTopics.value, id => ({ id: +id, isPopular: false }))
          : map(selectedTopics.value, id => ({ id: +id, isPopular: true }));

        await createBlockTopics({ blockId, data: { topics } });
        showAddTopicDialog.value = false;
        blockRefetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const handleDeleteBlockTopic = async(id: number) => {
      try {
        await deleteBlockTopics({ blockId, data: { topics: [{ id }] } });
        showDeleteDialog.value = false;
        blockRefetch.value();
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    const getNotSelectedTopics = (query: string) => {
      searchKeyword.value = query;
    };
    // #endregion 主題、熱門主題相關

    // #region （主題、主打影片）共用
    const handleShowDeleteDialog = (data: Topic | Work) => {
      currentData.value = data;
      showDeleteDialog.value = true;
    };

    const currentTableData = computed(() => {
      switch (currentTab.value) {
        case 'normal':
          return normalTopics.value;
        case 'popular':
          return popularTopics.value;
        default:
          return get(block.value, 'data.featureWorks');
      }
    });

    const handleChangeSortMode = () => {
      editMode.value = 'sort';

      switch (currentTab.value) {
        case 'normal':
          tempSortNormalTopics.value = normalTopics.value;
          break;
        case 'popular':
          tempSortPopularTopics.value = popularTopics.value;
          break;
        default:
          tempSortFeatureWorks.value = get(block.value, 'data.featureWorks', []);
      }
    };

    const clearTempData = () => {
      tempSortNormalTopics.value = [];
      tempSortPopularTopics.value = [];
      tempSortFeatureWorks.value = [];
    };

    const backToNormalEditMode = () => {
      clearTempData();
      editMode.value = 'normal';
    };

    const handleSaveDataSort = () => {
      if (['normal', 'popular'].includes(currentTab.value)) {
        let data;

        if (currentTab.value === 'normal') {
          const popular = map(popularTopics.value, (topic) => ({ id: topic.id, isPopular: true }));
          data = [...tempSortNormalTopics.value, ...popular];
        } else {
          const normal = map(normalTopics.value, (topic) => ({ id: topic.id, isPopular: false }));
          data = [...normal, ...tempSortPopularTopics.value];
        }

        handleSortBlockTopics(data);
        backToNormalEditMode();
        return;
      }

      handleSortBlockFeatureWorks(map(tempSortFeatureWorks.value, work => ({ id: work.id })));
      backToNormalEditMode();
    };

    const handleDeleteData = async(id: number) => {
      if (currentTab.value === 'featureWorks') {
        handleDeleteBlockFeatureWork(id);
        return;
      }
      handleDeleteBlockTopic(id);
    };

    const handleToolBtnClick = () => {
      if (currentTab.value === 'featureWorks') {
        editMode.value = 'worksEdit';
        return;
      }
      showAddTopicDialog.value = true;
    };
    // #endregion 共用

    onMounted(() => {
      fetchClassifications();
    });

    return {
      tabs,
      formattedTab,
      currentBlock,
      router,
      classifications,
      classificationIds,
      topics,
      isTopicLoading,
      showAddTopicDialog,
      handleToolBtnClick,
      isBlockLoading,
      isBlockFetching,
      currentData,
      showDeleteDialog,
      handleShowDeleteDialog,
      editMode,
      selectedTopics,
      currentTab,
      popularTopics,
      normalTopics,
      handleChangeSortMode,
      handleTopicsSort,
      backToNormalEditMode,
      handleFeatureWorkBack,
      handleSaveDataSort,
      currentTableData,
      handleCreateBlockTopics,
      handleDeleteBlockTopic,
      handleDeleteData,
      getNotSelectedTopics
    };
  }
});
