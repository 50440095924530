
import { computed, defineComponent, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { getCampaign, getCampaignCommission } from '@/services/api';
import CPS from './components/CPS.vue';
import CPA from './components/CPA.vue';
import { get, toUpper } from 'lodash';

enum ComponentMap {
  CPS = 'CPS',
  CPA = 'CPA',
  CPC = 'CPC'
}

export default defineComponent({
  components: {
    CPS,
    CPA
  },
  setup() {
    const campaignId = useRoute().params.id as string;
    const campaign = ref();

    const componentsType = computed(() => ComponentMap[toUpper(get(campaign.value, 'utmMedium'))] || '');

    const fetchCampaign = async() => {
      const { data } = await getCampaign({ campaignId });
      campaign.value = data;
    };

    onMounted(() => {
      fetchCampaign();
    });

    return {
      componentsType,
      campaign
    };
  }
});
