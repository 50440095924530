
import { computed, defineComponent, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { useCampaigns, useCampaignsDaily } from '@/composables/api';
import { DEFAULT_PAGE_SIZE, calibrateCampaignDaily } from '@/services/api';
import { PartialSimpleCampaign, CampaignType } from '@/interfaces';
import { formatLocalTime } from '@/utils/format-time';
import { getThousandSeparator } from '@/utils/render';
import { useRoute } from 'vue-router';
import { isEmpty, cloneDeep } from 'lodash';
import dayjs from 'dayjs';

const DEFAULT_ADJUST_INFO = {
  date: '',
  originalAmount: null,
  actualAmount: null
};

type UpdateType = 'date' | 'adjust'

export default defineComponent({
  setup() {
    const route = useRoute();
    const page = ref(1);
    const pageSize = ref(DEFAULT_PAGE_SIZE);
    const showDialog = ref(false);
    const showAdjustDialog = ref(false);
    const dialogEndDate = ref('');
    const currentAdjustData = ref<PartialSimpleCampaign>({});
    const adjustInfo = ref(cloneDeep(DEFAULT_ADJUST_INFO));

    const campaignId = computed(() => route.params.id);

    const { data, isLoading, isFetching, refetch } = useCampaignsDaily(
      { campaignId: campaignId.value },
      { page, pageSize }
    );

    const isEmptyData = computed(() => isEmpty(data.value?.data));

    const disabledEndDate = (datesOnCalendar: any): Boolean => {
      const startDateTimestamp = dayjs(data.value?.data?.startedAt).valueOf();
      const datesTimestamp = datesOnCalendar.getTime();
      const beforeStartDate = datesTimestamp < startDateTimestamp;

      return beforeStartDate;
    };

    const handleShowAdjustDialog = (data: PartialSimpleCampaign) => {
      currentAdjustData.value = data;
      adjustInfo.value.date = data.date;
      adjustInfo.value.originalAmount = data.originalAmount;
      adjustInfo.value.actualAmount = data.actualAmount;
      showAdjustDialog.value = true;
    };

    const handleUpdate = async(type: UpdateType) => {
      try {
        const formattedData = type === 'date'
          ? { endedAt: dayjs(dialogEndDate.value).endOf('day').toISOString() }
          : adjustInfo.value;

        await calibrateCampaignDaily(
          { campaignId: +campaignId.value },
          { data: formattedData }
        );
      } catch (error: any) {
        ElMessage.error(error.response?.data.message);
      }
    };

    const handleAdjustSubmit = async() => {
      await handleUpdate('adjust');
      await refetch.value();
      showAdjustDialog.value = false;
    };

    const handleDateSubmit = async() => {
      await handleUpdate('date');
      await refetch.value();
      showDialog.value = false;
    };

    watch(showAdjustDialog, (newShow) => {
      if (newShow) return;

      currentAdjustData.value = {};
      adjustInfo.value = cloneDeep(DEFAULT_ADJUST_INFO);
    });

    return {
      page,
      showDialog,
      showAdjustDialog,
      dialogEndDate,
      data,
      isLoading,
      isFetching,
      isEmptyData,
      formatLocalTime,
      disabledEndDate,
      getThousandSeparator,
      currentAdjustData,

      adjustInfo,
      handleShowAdjustDialog,
      handleUpdate,
      handleDateSubmit,
      handleAdjustSubmit
      // FILTER_OPTIONS,
      // handleFilterChange,
    };
  }
});
