
import { computed, defineComponent, onMounted, ref, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { useChannelCoupons } from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  exportChannelCouponsStatistics,
  getChannelCouponStatistics,
  Response,
  SortOrder,
  exportChannelCouponStatistics
} from '@/services/api';
import { ChannelCouponStatistics } from '@/interfaces';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { find } from 'lodash';
import { useRoute, useRouter } from 'vue-router';
import { usePage } from '@/composables/page';

type SortKey = 'date' | 'usage'

export default defineComponent({
  setup() {
    const detailPage = ref(1);
    const detailPageSize = ref(DEFAULT_PAGE_SIZE);
    const detailData = ref<Response<ChannelCouponStatistics>>();
    const isDetailLoading = ref(false);
    const dateRange = ref(null);
    const startDate = ref();
    const endDate = ref();
    const sortKey = ref<SortKey>();
    const sortOrder = ref<SortOrder>();

    const router = useRouter();
    const route = useRoute();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const currentQuery = computed(() => {
      const { channelId, couponId } = route.query;
      return { channelId, couponId };
    });
    const isDetailPage = computed(() => !!(currentQuery.value.channelId && currentQuery.value.couponId));

    // TODO: maybe need refactor
    const { data, isLoading, isFetching, refetch } = useChannelCoupons(
      { page, pageSize, startDate, endDate },
      { enabled: true }
    );

    const fetchChannelCoupon = async({ reload }: { reload?: boolean }) => {
      try {
        isDetailLoading.value = true;

        if (reload) {
          detailPage.value = 1;
        }

        const data = await getChannelCouponStatistics(
          {
            channelId: +currentQuery.value.channelId,
            couponId: +currentQuery.value.couponId
          },
          {
            query:
            {
              page: detailPage.value,
              pageSize: detailPageSize.value,
              startDate: startDate.value,
              endDate: endDate.value,
              sortKey: sortKey.value,
              sort: sortOrder.value
            }
          }
        );
        console.log('fetchChannelCoupon');
        detailData.value = data;
      } catch (error: any) {
        ElMessage.error(error.response?.data.message);
      } finally {
        isDetailLoading.value = false;
      }
    };

    watch(isDetailPage, (newVal) => {
      if (newVal) {
        fetchChannelCoupon({ reload: true });
        return;
      }
      refetch.value();
    });

    const handleDateChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
      } else {
        startDate.value = formatISOString(dateRange.value[0]);
        endDate.value = formatISOString(dateRange.value[1]);
      }

      if (isDetailPage.value) {
        fetchChannelCoupon({ reload: false });
      }
    };

    // TODO: 處理 excel 匯出串接
    const handleExcelExport = async() => {
      try {
        let data;

        if (isDetailPage.value) {
          data = await exportChannelCouponStatistics(
            {
              channelId: +currentQuery.value.channelId,
              couponId: +currentQuery.value.couponId
            }, {
              query:
            { startDate: startDate.value, endDate: endDate.value }
            });
        } else {
          data = await exportChannelCouponsStatistics({
            query: {
              startDate: startDate.value,
              endDate: endDate.value,
              sortKey: sortKey.value,
              sort: sortOrder.value
            }
          });
        }

        const currentDate = dayjs().format('YYYY-MM-DD');
        const couponName = detailData.value?.data[0]?.couponName;
        const fileName = isDetailPage.value ? `${currentDate}${couponName}統計.xlsx` : `${currentDate}體驗卷統計.xlsx`;

        download(data, fileName, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      } catch (error: any) {
        ElMessage.error(error.response?.data.message);
      }
    };

    const handleGoToDetail = ({ channelId, couponId }: {
      channelId: number
      couponId: number
    }) => {
      router.push({ query: { channelId, couponId } });
    };

    const getCouponUsage = (id: number | string, couponUsage) => {
      const currentData = find(couponUsage, ['id', id]);
      return currentData ? currentData.usage : '-';
    };

    const translateSort = {
      ascending: 'asc',
      descending: 'desc'
    };

    const handleSort = (event: {
      column: ProxyConstructor
      prop: SortKey | null
      order: string | null
    }) => {
      sortKey.value = event.prop;
      sortOrder.value = translateSort[event.order];
      fetchChannelCoupon({ reload: false });
    };

    // cache搜索條件在querystring
    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });
    // 從querystring帶入搜索條件
    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate as string;
        endDate.value = query.endDate as string;
        // 避免[null,null], datePicker會顯示當天日期
        if (startDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    onMounted(() => {
      if (isDetailPage.value) {
        fetchChannelCoupon({ reload: true });
      }
    });

    return {
      page,
      data,
      dateRange,
      isLoading,
      isFetching,
      isDetailPage,
      detailPageSize,
      detailPage,
      detailData,
      isDetailLoading,
      getThousandSeparator,
      formatLocalTime,
      handleDateChange,
      handleExcelExport,
      getCouponUsage,
      fetchChannelCoupon,
      handleGoToDetail,
      handleSort,
      setPageQuery
    };
  }
});
