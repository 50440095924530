
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useChannelStatistics, useDailyCampaign } from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  getChannels,
  MAX_PER_PAGE,
  SortOrder,
  refreshDailyCampaignStatistics,
  exportDailyCampaign,
  OrderSortKey
} from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import { Channel } from '@/interfaces';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import PanelGroup from './components/PanelGroup.vue';
import { getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { useKeyword, usePage, useSort } from '@/composables';

export default defineComponent({
  components: {
    Filter,
    PanelGroup,
    SearchBox
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const startDate = ref();
    const endDate = ref();
    const channelIds = ref();
    const dateRange = ref(['']);
    const date = ref('');
    const channels = ref<Channel[]>([]);
    const channelOptions = computed(() => channels.value.map(({ utmSource, id }) => ({ label: `${utmSource}`, value: `${id}` })));
    const defaultTime = ref([new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 2, 1, 23, 59, 59)]);

    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const {
      data,
      isLoading,
      isFetching,
      refetch: refetchDailyCampaign
    } = useDailyCampaign({ page, pageSize, startDate, endDate, channelIds, sortKey, sort: sortOrder, keyword });

    const {
      data: statisticsData,
      isLoading: isStatisticsLoading,
      isFetching: isStatisticsFetching,
      refetch: refetchStatistics
    } = useChannelStatistics({ startDate, endDate, channelIds, keyword });

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }

      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const isRefreshLoading = ref(false);
    const refresh = async() => {
      if (!date.value) return;

      isRefreshLoading.value = true;

      await refreshDailyCampaignStatistics({
        data: {
          startDate: dayjs(date.value[0]).toISOString(),
          endDate: dayjs(date.value[1]).toISOString()
        }
      });

      refetchDailyCampaign.value();
      refetchStatistics.value();

      isRefreshLoading.value = false;
    };

    const setDisableDate = (time: Date) => {
      return time.getTime() > Date.now();
    };

    const handleFilterChange = (event: FilterEvent) => {
      channelIds.value = event[0];
    };

    const fetchChannels = async() => {
      channels.value = (await getChannels({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const handleExcelExport = async() => {
      const data = await exportDailyCampaign({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          channelIds: channelIds.value,
          sortKey: sortKey.value,
          sort: sortOrder.value,
          keyword: keyword.value
        }
      });

      const currentDate = dayjs().format('YYYY-MM-DD');
      // 下載匯出檔案
      download(data, `${currentDate}渠道流量統計.xlsx`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
    };

    onMounted(() => {
      fetchChannels();
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.MULTI_SELECTOR,
          label: '渠道名稱',
          placeholder: '請選擇',
          options: channelOptions.value
        }
      ];
    });

    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        if (startDate.value && endDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      dateRange,
      date,
      isLoading,
      isFetching,
      statisticsData,
      isStatisticsLoading,
      isStatisticsFetching,
      filterOptions,
      channelOptions,
      defaultTime,
      keyword,
      sortKey,
      sortOrder,
      getThousandSeparator,
      handleFilterChange,
      formatLocalTime,
      handleDateRangeChange,
      sortChange,
      handleExcelExport,
      refresh,
      isRefreshLoading,
      setDisableDate,
      setPageQuery
    };
  }
});
