
import { defineComponent, onMounted, ref } from 'vue';
import permissionUnits from '@/components/permission-units/index.vue';
import { getThousandSeparator } from '@/utils/render';
import { useRoute } from 'vue-router';
import { getCampaignCommission } from '@/services/api';
import PanelGroup from './PanelGroup.vue';

export default defineComponent({
  components: {
    permissionUnits,
    PanelGroup
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  setup() {
    const campaignId = useRoute().params.id as string;
    const campaignCommission = ref();

    const fetchCampaignCommission = async() => {
      const { data } = await getCampaignCommission({ campaignId });
      campaignCommission.value = data;
    };

    onMounted(() => {
      fetchCampaignCommission();
    });

    return {
      campaignCommission,
      getThousandSeparator
    };
  }
});
